export interface Company {
  id: string,
  companyName: string,
  placeOfBusiness: string,
  companyForm: string,
  status: string,
  state: string,
  code: string,
  industryCode?: string,
  peppolParticipantID?: string,
  dateOfRegistration?: string,
}

export interface JsonObject<T> {
  id: string,
  entityType: string, // Owner entity type e.g. Company
  entityID: string, // Owner entity id e.g. Company::id
  dataType: string, // Type of jsonData content e.g. Catalogue, CatalogueLine or CatalogueProductItem
  jsonData: T,
  sessionAccountID?: string
}

export interface JsonObjectRequestData<T> {
  id?: string,
  dataType: string, // Type of jsonData content e.g. Catalogue, CatalogueLine or CatalogueProductItem
  jsonData: T,
  sessionAccountID?: string
}

export interface StandardBusinessDocument {
  id: string,
  ownerID: string,
  createdOn: string,
  senderId: string,
  receiverId: string,
  documentIDStandard: string,
  documentIDType: string,
  isRead: string,
  document: any,
  status: SBDStatus,
  accountingNumber: string, // GeneralLedger entryNumber
  sessionAccountID?: string
}

export enum SBDStatus {
  // Sales invoice statuses
  CREATED = "created",
  SENDING = "sending",
  SENT = "sent",
  // Purchase invoice statuses
  RECEIVED = "received",
  ACCEPTED = "accepted",
  PAID = "paid"
}

export interface FinvoiceDocument {
  id: string,
  ownerID: string,
  createdOn: string,
  senderId: string,
  receiverId: string,
  isRead: string,
  document: any,
  status: SBDStatus,
  accountingNumber: string,
  sessionAccountID?: string
}

export interface SessionAccount {
  id: string,
  scope: string,
  role: SessionRole,
  createdOn: string,
  updatedOn: string,
  data: CompanyCookie
}

export enum SessionRole {
  Anonymous = "Anonymous",
  Admin = "Admin"
}

export interface CompanyCookie {
  companyID: string,
  companyCode: string
}