import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Card from '../../common/Card';
import Page from '../../common/Page';
import SalesInvoicesTable from '../../SalesInvoicesTable';

const SalesView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Page
        breadcrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/sales", label: "sales", current: true}
        ]}
      >
        <Card title={t("app.components.SalesView.callsForTenders.heading")}>
          <p>Inbox-tyyppinen taulukko, esim 10 viimeisintä. Klikkaamalla tai rullaamalla lisää.
          Suodattamalla erilaisia listauksia</p>
        </Card>
        <br />
        <Card title={t("app.components.SalesView.tenders.heading")}>
          <p>Inbox-tyyppinen taulukko, esim 10 viimeisintä. Klikkaamalla tai rullaamalla lisää.
          Suodattamalla erilaisia listauksia</p>
        </Card>
        <br />
        <Card title={t("app.components.SalesView.ordersReceived.heading")}>
          <p>Inbox-tyyppinen taulukko, esim 10 viimeisintä. Klikkaamalla tai rullaamalla lisää.
          Suodattamalla erilaisia listauksia</p>
        </Card>
        <br />
        <Card 
          title={t("app.components.SalesView.salesInvoices.heading")} 
          titleAction={
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
            <Button 
              startIcon={<AddCircleOutlineIcon/>}
              variant="text"
              onClick={() => navigate("/salesInvoices/new")}
            >
              {t("app.components.SalesView.salesInvoices.addBtn")}
            </Button>
          </Box>
          }
        >
          <SalesInvoicesTable/>
        </Card>
      </Page>
    </>
  )
}

export default SalesView;