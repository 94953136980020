import axios, { AxiosResponse } from 'axios';
import { Company, FinvoiceDocument, JsonObject, JsonObjectRequestData, SessionAccount, StandardBusinessDocument } from '../model/OmaXTypes';
import { Catalogue, Item } from '../model/Peppol/PreAwardCatalogue';
import { BusinessDocument, ChartOfAccounts, CreateGeneralLedgerEntriesWrapper, CreateInvoiceRequest, CreateReportRequest, GeneralLedgerEntry, Product, Report } from '../model/AppModels';
import { EpcisEvent, EpcisTypes } from '../model/EpcisModels';
import { ObjectSerializer } from '../utils/ObjectSerializer';
import { EpcisEventRequest } from '../components/pages/Products/CreateEpcisEventDialog';

const domain = ((hostname: string) => {
  if (hostname.includes("minisuomi.fi")) {
    return "https://omayritys-api.minisuomi.fi/api";
  }
  else if (hostname.includes("minisuomi.net")) {
    return "https://omayritys-api.minisuomi.net/api";
  }
  else if (hostname.includes("minisuomi.eu")) {
    return "https://omayritys-api.minisuomi.eu/api";
  }
  return process.env.REACT_APP_OMAYRITYS_FUNCTIONS_URL || "http://localhost:7071/api";
})(window.location.hostname);

const apiRootUrl = domain;
console.log("apiRootUrl:", apiRootUrl);
const apiKey = process.env.REACT_APP_OMAYRITYS_FUNCTIONS_API_KEY || "";

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

export async function Signup(businessId: string): Promise<AxiosResponse<SessionAccount>> {
  return api.post<SessionAccount>("/Companies/Signup", null, {params: {bid: businessId}});
}

export async function Login(token: string): Promise<AxiosResponse<SessionAccount>> {
  return api.post<SessionAccount>("/Companies/Login", token);
}

export async function SelectCompany(code: string): Promise<AxiosResponse<SessionAccount>> {
  return api.post<SessionAccount>("/Companies/SetSessionCompany", null, {params: {code}});
}

export async function GetSession(): Promise<AxiosResponse<SessionAccount>> {
  return api.get<SessionAccount>("/Companies/GetSession");
}

export async function Logout(): Promise<AxiosResponse<string>> {
  return api.post<string>("/Companies/Logout");
}

export async function DeleteSession(): Promise<AxiosResponse<string>> {
  return api.delete<string>("/Companies/DeleteSession");
}

export async function GetSessionCompany(): Promise<AxiosResponse<Company>> {
  // This request contains a session cookie that contains information about the current company
  return api.get<Company>("/Companies/GetSessionCompany");
}

export async function PostCatalogue(data: JsonObjectRequestData<Catalogue|Item|Product>): Promise<AxiosResponse<JsonObject<Catalogue|Item|Product>>> {
  return api.post<JsonObject<Catalogue|Item>>("/Companies/PostCatalogue", data);
}

export async function GetCatalogues(dataType: string, id?: string): Promise<AxiosResponse<JsonObject<Catalogue|Item|Product>[]>> {
  return api.get<JsonObject<Catalogue|Item|Product>[]>("/Companies/GetCatalogues", {params: {dataType, id}});
}

export async function DeleteCatalogue(id: string): Promise<AxiosResponse<void>> {
  return api.post("/Companies/DeleteCatalogue", null, {params: {ID: id}});
}

export async function ListPeppolDocuments(): Promise<AxiosResponse<StandardBusinessDocument[]>> {
  return api.get<StandardBusinessDocument[]>("/Peppol/ListDocuments");
}

export async function GetPeppolDocumentContent(id: string): Promise<AxiosResponse<string>> {
  return api.get<string>(`/Peppol/GetDocumentContent/${id}`);
}

export async function GetInvoiceItems(id: string): Promise<AxiosResponse<Product[]>> {
  return api.get<Product[]>(`/Peppol/GetInvoiceItems/${id}`);
}

export async function ListFinvoiceDocuments(): Promise<AxiosResponse<FinvoiceDocument[]>> {
  return api.get<FinvoiceDocument[]>("/Finvoice/ListDocuments");
}

export async function ListBusinessDocuments(): Promise<AxiosResponse<JsonObject<BusinessDocument<any>>[]>> {
  return api.get<JsonObject<BusinessDocument<any>>[]>("/Companies/ListBusinessDocuments");
}

export async function GetFinvoiceDocumentContent(id: string): Promise<AxiosResponse<string>> {
  return api.get<string>(`/Finvoice/GetDocumentContent/${id}`);
}

export async function SearchParticipant(participantId: string): Promise<AxiosResponse<string>> {
  return api.get<string>("/Peppol/SearchParticipant", {params: {participant: participantId}});
}

export async function CreatePeppolInvoice(data: CreateInvoiceRequest): Promise<AxiosResponse<JsonObject<StandardBusinessDocument>>> {
  return api.post<JsonObject<StandardBusinessDocument>>("/Peppol/CreateInvoice", data);
}

export async function SendPeppolInvoice(invoiceId: string): Promise<AxiosResponse<JsonObject<StandardBusinessDocument>>> {
  return api.post<JsonObject<StandardBusinessDocument>>(`/Peppol/SendInvoice/${invoiceId}`);
}

export async function ReceivePeppolInvoice(invoiceId: string): Promise<AxiosResponse<JsonObject<StandardBusinessDocument>>> {
  return api.get<JsonObject<StandardBusinessDocument>>(`/Peppol/ReceiveInvoice/${invoiceId}`);
}

export async function AcceptPeppolInvoice(invoiceId: string): Promise<AxiosResponse<JsonObject<StandardBusinessDocument>>> {
  return api.post<JsonObject<StandardBusinessDocument>>(`/Peppol/AcceptInvoice/${invoiceId}`);
}

export async function PostEpcisEvents(productId: string, events: EpcisEvent[]): Promise<AxiosResponse<JsonObject<EpcisEvent>[]>> {
  return api.post<JsonObject<EpcisEvent>[]>("/Companies/PostEpcisEvents", events, {params: {productId}});
}

export async function GetEpcisEvents(productId: string, eventId?: string): Promise<JsonObject<EpcisEvent>[]> {
  const res = await api.get<JsonObject<EpcisEvent>[]>("/Companies/GetEpcisEvents", {params: {productId, eventId}});
  const ser = new ObjectSerializer(EpcisTypes, {});
  const objects = res.data.map(it => ({...it, jsonData: ser.deserialize(it.jsonData, "EpcisEvent") as EpcisEvent}));
  return objects;
}

export async function PostCreateEpcisEvent(productId: string, events: EpcisEventRequest): Promise<AxiosResponse<JsonObject<EpcisEvent>>> {
  return api.post<JsonObject<EpcisEvent>>("/Companies/CreateEpcisEvent", events, {params: {productId}});
}

export async function PostPeppolInvoice(entries: CreateGeneralLedgerEntriesWrapper): Promise<AxiosResponse<GeneralLedgerEntry[]>> {
  return api.post<GeneralLedgerEntry[]>("/Peppol/PostInvoice", entries);
}

export async function PostNSGReceipt(entries: CreateGeneralLedgerEntriesWrapper): Promise<AxiosResponse<GeneralLedgerEntry[]>> {
  return api.post<GeneralLedgerEntry[]>("/Finvoice/PostNSGReceipt", entries);
}

export async function ListGeneralLedgerEntries(): Promise<AxiosResponse<GeneralLedgerEntry[]>> {
  return api.get<GeneralLedgerEntry[]>("/Companies/ListGeneralLedgerEntries");
}

export async function GetPeppolInvoiceLedgerEntries(id: string): Promise<AxiosResponse<CreateGeneralLedgerEntriesWrapper>> {
  return api.get<CreateGeneralLedgerEntriesWrapper>(`/Peppol/GetInvoiceLedgerEntries/${id}`);
}

export async function GetNSGReceiptLedgerEntries(id: string): Promise<AxiosResponse<CreateGeneralLedgerEntriesWrapper>> {
  return api.get<CreateGeneralLedgerEntriesWrapper>(`/Finvoice/GetNSGReceiptLedgerEntries/${id}`);
}

export async function GetChartOfAccounts(): Promise<AxiosResponse<ChartOfAccounts>> {
  return api.get<ChartOfAccounts>("/Companies/GetChartOfAccounts");
}

export async function GetSBDContent(accountingNumber: string, standard: string, dataType: string): Promise<AxiosResponse<string>> {
  return api.get<string>("/Companies/GetSBDContent", {params: {accountingNumber, standard, dataType}});
}

export async function PostCreateReport(crr: CreateReportRequest): Promise<AxiosResponse<any>> {
  return api.post<any>("/Companies/CreateReport", crr);
}

export async function ListReports(): Promise<AxiosResponse<Report[]>> {
  return api.get<Report[]>("/Companies/ListReports");
}

export async function GetReportContent(documentId: string, reportType: string): Promise<AxiosResponse<string>> {
  return api.get<string>("/Companies/GetReportContent", {params: {documentId, reportType}});
}

export async function SendReport(reportId: string): Promise<AxiosResponse<Report>> {
  return api.post<Report>(`/Companies/SendReport/${reportId}`);
}